import { Formik } from "formik";
import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAt,faKey } from '@fortawesome/free-solid-svg-icons'
import "./login.css"


import Swal from 'sweetalert2'


function Login() {
  const [url,setUrl]= useState(sessionStorage.getItem('url'));
  const navigate = useNavigate();
  const [app_name, setAppName]=useState(sessionStorage.getItem('app_name'));
  const [looder,setLoader] = useState(false)
  return (
      <div className="login">
        <title>{app_name} - الحفلات</title>
        <div className="container text-center">

                <h1 className="header mt-5 mb-4">
                    تسجيل الدخول    
                </h1>
                <Formik
                    initialValues={{ email: '', password: '' }}
                    validate={values => {
                    const errors = {};
                    if (!values.email) {
                      errors.email = 'هذا الحقل مطلوب';
                    } else if (
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                    ) {
                      errors.email = 'Invalid email address';
                    }

                    if (!values.password) {
                      errors.password = 'هذا الحقل مطلوب';
                    } else if (values.password.length<4) {
                      errors.password = 'كلمة المرور قصيرة';
                    }
                    return errors;
                  }}
              onSubmit={(values, { setSubmitting }) => {
                setLoader(true)
                setTimeout(() => {
                  axios.post(url+"/login", values,{
                    headers: {
                      'Accept': 'application/json',
                      "ngrok-skip-browser-warning": "69420"
                    }
                  })
                  .then(function (response) {
                    // console.log(response);
                    // alert(response.data.message);
                    sessionStorage.setItem('token',response.data.token)
                    Swal.fire({
                      position: "center-center",
                      icon: "success",
                      title: response.data.message,
                      showConfirmButton: false,
                      timer: 1500
                    });
                    setTimeout(()=>{
                      navigate("/event")
                    },1500)
                  })
                  .catch(function (error) {
                      console.log(error);
                      Swal.fire({
                        icon: "error",
                        title: "خطأ",
                        text: error.response.data.message,
                        // footer: '<a href="#">Why do I have this issue?</a>'
                      });
                      setLoader(false)
                  });
                  console.log(values)
                  // alert(JSON.stringify(values, null, 2));
                  setSubmitting(false);
                }, 0);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="icon m-auto mb-4">
                    <input
                      type="email"
                      name="email"
                      placeholder="البريد الإلكتروني"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    <FontAwesomeIcon className="item-icon" icon={faAt} />
                  <div className="error mb-3 mt-1 text-right">
                    {errors.email && touched.email && errors.email}
                  </div>
                  </div>
                  <div className="icon m-auto mb-4">
                    <input
                      type="password"
                      name="password"
                      placeholder="كلمة المرور"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                    <FontAwesomeIcon className="item-icon" icon={faKey} />
                    <div className="error mt-1 mb-3">
                      {errors.password && touched.password && errors.password}
                    </div>
                  </div>
                  <button className="submit mx-auto" type="submit" disabled={isSubmitting}>
                      تسجيل الدخول  
                      {looder ? <div className='submit-loader'></div>:""}
                  </button>
                  {/* <div className="d-flex">
                    <FacebookLogin1/>
                    <GoogleButton1/>
                  </div> */}
                  {/* <div className="signup mt-5 mb-5">
                      <h6>ليس لديك حساب بعد؟</h6>
                      <div>
                        <Link to="/signup">التسجيل</Link>
                      </div>
                  </div> */}
                </form>
              )}
          </Formik>
        </div>
      </div>
    );
  }
  
  export default Login;