import { Link } from "react-router-dom";
import React, { useState,useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2'
import { Formik,Field } from "formik";

import "./event.css"

function Event() {
  if(!sessionStorage.getItem('token')){
    window.location.replace("./login")
  }
  const [url,setUrl]= useState(sessionStorage.getItem('url'));
  const [eventsUrl, setEventsUrl] = useState(url + "/events");
  const [token,setToken]= useState(sessionStorage.getItem('token'));
  const [events,setEvents]=useState();
  const [links,setLinks]=useState();
  const [id,setId]=useState();
  const [app_name, setAppName]=useState(sessionStorage.getItem('app_name'));
  const [loader, setLoader]=useState(true);
  useEffect(() => {
    axios.get(eventsUrl,{
      headers: {
        'Accept': 'application/json',
        "ngrok-skip-browser-warning": "69420",
        "Authorization":`Bearer ${token}`
      }
    }).then((response) => {
      setLoader(false)
      setEvents(response.data.events.data)
      setLinks(response.data.events.links)
      return 
    }).catch(function (error) {});
}, []);

const addfile = (id)=> {
  const { value: file } = Swal.fire({
    title: "اختر الملف",
    input: "file",
    inputAttributes: {
      "accept": ".xlsx, .xls",
      "aria-label": "Upload your profile picture"
    },
    howCancelButton: true,
    showCloseButton: true,
    showDenyButton: true,
    confirmButtonText: "إرسال",
    cancelButtonText: `
    <i class="fa fa-thumbs-down"></i>`,
    denyButtonText: "إلغاء"
  }).then(function(response) {
    if(!response.isConfirmed) return;
    console.log(response)
        axios.post(url+"/events/"+ id +"/send-more-invites", {
          "invitees_file": response.value
        },{
            headers: {
              'Accept': 'application/json',
              "ngrok-skip-browser-warning": "69420",
              "Authorization":`Bearer ${token}`,
              'Content-Type': 'multipart/form-data'
            }
          })
        .then(function (response) {
            // window.location.replace("./event")
            Swal.fire({
                position: "center-center",
                icon: "success",
                title: response.data.message,
                showConfirmButton: false,
                timer: 1500
            });   
        })
        .catch(function (error) {
            Swal.fire({
                icon: "error",
                title: "خطأ",
                text: error.response.data.message,
            });
        })
  });
  
}

const pagination = (link)=> {
  setEventsUrl(link)
  axios.get(link,{
    headers: {
      'Accept': 'application/json',
      "ngrok-skip-browser-warning": "69420",
      "Authorization":`Bearer ${token}`
    }
  }).then((response) => {
    setLoader(false)
    setEvents(response.data.events.data)
    setLinks(response.data.events.links)
    return 
  });
  
}

const end = (id)=>{
  setId(id)
  Swal.fire({
    title: "هل أنت متأكد؟",
    text: "لن تتمكن من الرجوع عن هذا الإجراء!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "تأكيد",
    cancelButtonText: "إلغاء"
  }).then((result) => {
    if (result.isConfirmed) {
      axios.patch(url+"/events/"+id+"/end",{}, {
        headers: {
          'Accept': 'application/json',
          "ngrok-skip-browser-warning": "69420",
          "Authorization":`Bearer ${token}`
        }
      }).then((response) => {
        Swal.fire({
          position: "center-center",
          icon: "success",
          title: response.data.message,
          showConfirmButton: false,
          timer: 1500
        });
          axios.get(url+"/events",{
            headers: {
              'Accept': 'application/json',
              "ngrok-skip-browser-warning": "69420",
              "Authorization":`Bearer ${token}`
            }
          }).then((response) => {
              setEvents(response.data.events.data)
              setLinks(response.data.events.links)
              return 
          }).catch(function (error) {});;
          return 
      }).catch(function (error) {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: error.response.data.message,
        });
    });
    }
  });
    
}
const start = (id)=>{
  setId(id)
  Swal.fire({
    title: "هل أنت متأكد؟",
    text: "لن تتمكن من الرجوع عن هذا الإجراء!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "تأكيد",
    cancelButtonText: "إلغاء"
  }).then((result) => {
    if (result.isConfirmed) {
      axios.patch(url+"/events/"+id+"/start",{}, {
        headers: {
          'Accept': 'application/json',
          "ngrok-skip-browser-warning": "69420",
          "Authorization":`Bearer ${token}`
        }
      }).then((response) => {
        Swal.fire({
          position: "center-center",
          icon: "success",
          title: response.data.message,
          showConfirmButton: false,
          timer: 1500
        });
          axios.get(url+"/events",{
            headers: {
              'Accept': 'application/json',
              "ngrok-skip-browser-warning": "69420",
              "Authorization":`Bearer ${token}`
            }
          }).then((response) => {
              setEvents(response.data.events.data)
              setLinks(response.data.events.links)
              return 
          }).catch(function (error) {});
          return 
      }).catch(function (error) {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: error.response.data.message,
        });
    });
    }
  });
}
    return (
      
      <div className="event mt-4">
        <title>{app_name} - الحفلات</title>
        {
          loader?
            <div className="loader-container">
              <div className="loader"></div>
            </div>:""
        }
        <div className="container">
            <div className="add-event">
                <Link  to="../createevent"><span>حفل جديد</span></Link>
            </div>
            <h2 className="event-name-head">حفلات الزفاف</h2>
            <div className="tableapp">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">الاسم</th>
                    <th scope="col">التاريخ</th>
                    <th scope="col">عدد المدعوين</th>
                    <th scope="col">الحالة</th>
                    <th scope="col">الإجراءات</th>
                  </tr>
                </thead>
                <tbody>
                  {events?events.map((event)=>
                    <tr key={event.id}>
                      <td>{event.name}</td>
                      <td>{event.time}</td>
                      <td>{event.invites_count}</td>
                      <td>{event.status}</td>
                      <td>
                        {
                          event.status_key==="not_started"? <button onClick={()=> start(event.id)} className="m-2 btn btn-success">بدأ الحفل</button>
                          :event.status_key==="in_progress"?
                          <><button onClick={()=>end(event.id)} className="btn btn-danger m-2">إنهاء الحفل</button> 
                          <Link className="btn btn-primary" to={"/scanqr?event_id="+event.id}>تسجيل الحضور</Link></>
                          :""
                        }
                        {event.status_key==="not_started"?<button onClick={()=> addfile(event.id)} className="btn btn-primary m-2">
                        إرسال المزيد من الدعوات
                        </button>:""}
                        
                        </td>
                    </tr>
                  ):""}
                </tbody>
              </table>
            </div>
            <div id="wrapper">
              <ul id="border-pagination">
                {links?links.links.map((link)=> 
                (
                  <li key={link.label}><a className={link.active==true?"active":""  || link.url==null?"not-clickable":""} onClick={()=> {
                    pagination(link.url)
                  }
                  }>{link.label}</a></li>
                )
                ):""}
              </ul> 
            </div>
</div>


                        
      </div>
    );
  }
  
  export default Event;
  