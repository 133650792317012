import React, { useState,useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bs-stepper/dist/css/bs-stepper.min.css';
import { Formik,Field } from "formik";
import DatepickerField from "../signup/date";
import axios from 'axios';
import './createevent.css';
import Swal from 'sweetalert2'


function CreateEvent() {
    if(!sessionStorage.getItem('token')){
        window.location.replace("./login")
    }
    const [url,setUrl]= useState(sessionStorage.getItem('url'));
    const [image, setImage] = useState("");
    const [allvalues, setAllvalues] = useState({});
    const [page, setPage] = useState(1);
    const [display, setDisplay] = useState("d-none");
    const [errors, setErrors] = useState("");
    const [num, setNum] = useState(0);
    const [token,setToken]= useState(sessionStorage.getItem('token'));
    const [loader, setLoader]=useState(true);

    const [wedding_cards,setWedding_cards]=useState([])
    const [excel,setExcel]=useState("")
    const [app_name, setAppName]=useState(sessionStorage.getItem('app_name'));
    useEffect(() => {
        axios.get(url+"/wedding-cards",{
          headers: {
            'Accept': 'application/json',
            "ngrok-skip-browser-warning": "69420",
            "Authorization":`Bearer ${token}`
          }
        }).then((response) => {
            setLoader(false)
            // console.log(response.data)
            setWedding_cards(response.data.home_images)
            return 
        }).catch(function (error) {});;
    }, []);
    useEffect(() => {
        axios.get(url+"/settings",{
          headers: {
            'Accept': 'application/json',
            "ngrok-skip-browser-warning": "69420",
            "Authorization":`Bearer ${token}`
          }
        }).then((response) => {
            console.log(response.data)
            setExcel(response.data.data)
            return 
        }).catch(function (error) {});;
    }, []);
    const nextpage = () => {
        // setTimeout(() => {
        //     // if(Object.keys(errors).length==0&&num>0){setDisplay("block"); setPage(2)}else{setDisplay("d-none")}
        // }, 2000);
        console.log("ddd")


    }
    const prevpage = () => {
        setDisplay("d-none")
        setPage(1)
    }
    return(
        <div className='login edit createevent'>
            {
                loader?
                <div className="loader-container">
                    <div className="loader"></div>
                </div>:""
            }
            <title>{app_name} - إنشاء حفل</title>
            <div className='container'>
                <div id="stepper1" className="bs-stepper">
                    <div className="bs-stepper-header">
                        <div className="step" data-target="#test-l-1">
                            <button className="step-trigger">
                                <span className="bs-stepper-label">الخطوة {page} من 2</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className='lines'>
                    <div className={page===2?"line1 full":"line1"}></div>
                </div>
                        <div id="test-l-1" className={display==="d-none"?"block content active dstepper-block":"d-none content active"} >
                            <h1 className="header mt-5 mb-4">
                                أضف حفل جديد        
                            </h1>
                                <Formik
                                    initialValues={{ name: '',time:"", location:"",groom_name:"",
                                        groom_family:"",bride_name:"",bride_family:"",wedding_card_id:""}}
                                    validate={values => {
                                        const errors = {};
                                        if (!values.name) {
                                            errors.name = 'هذا الحقل مطلوب';
                                        }
                                        if (values.name) {
                                            setNum(1);
                                        }
                                        if (!values.time) {
                                            errors.time = 'هذا الحقل مطلوب';
                                        }
                                        if (!values.location) {
                                            errors.location = 'هذا الحقل مطلوب';
                                        }
                                        if (!values.groom_name) {
                                            errors.groom_name = 'هذا الحقل مطلوب';
                                        }
                                        // if (!values.groom_family) {
                                        //     errors.groom_family = 'هذا الحقل مطلوب';
                                        // }
                                        if (!values.bride_name) {
                                            errors.bride_name = 'هذا الحقل مطلوب';
                                        }
                                        // if (!values.bride_family) {
                                        //     errors.bride_family = 'هذا الحقل مطلوب';
                                        // }
                                        if (!values.wedding_card_id) {
                                            errors.wedding_card_id = 'هذا الحقل مطلوب';
                                        }
                                        return errors;
                                    }}
                                onSubmit={(values, { setSubmitting }) => {
                                    console.log(values)
                                    setTimeout(() => {
                                        values.time=new Date(values.time).toLocaleString('sv-SE');
                                        setAllvalues(values)
                                        axios.get(url+"/events/preview", {
                                            params: values,
                                            headers: {
                                                'Accept': 'application/json',
                                                "ngrok-skip-browser-warning": "69420",
                                                "Authorization":`Bearer ${token}`,
                                                
                                            }
                                        })
                                        .then(function (response) {
                                            // console.log()
                                            setImage(response.data.src)
                                            Swal.fire({
                                                position: "center-center",
                                                icon: "success",
                                                title: response.data.message,
                                                showConfirmButton: false,
                                                timer: 1500
                                            });   
                                            setTimeout(() => {
            
                                                if(Object.keys(errors).length===0&&num>0){setDisplay("block"); setPage(2)}else{setDisplay("d-none")}
                                            }, 2000);
                                        })
                                        .catch(function (error) {
                                            Swal.fire({
                                                icon: "error",
                                                title: "خطأ",
                                                text: error.response.data.message,
                                                // footer: '<a href="#">Why do I have this issue?</a>'
                                              });
                                        }).finally(() => {
                                            setSubmitting(false);
                                        });
                                }, 0);
                            }}
                            >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                /* and other goodies */
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <input
                                        type="text"
                                        name="name"
                                        placeholder="اسم الحفل"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.name}
                                />
                                {errors.name && touched.name && errors.name}
                                <Field value={values.time} onChange={handleChange} name="time" component={DatepickerField} />
                                {errors.time && touched.time && errors.time}
                                <input
                                        type="text"
                                        name="location"
                                        placeholder="المكان"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.place}
                                />
                                {errors.location && touched.location && errors.location}
                                <input
                                        type="text"
                                        name="groom_name"
                                        placeholder="اسم العريس"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.groom_name}
                                />
                                {errors.groom_name && touched.groom_name && errors.groom_name}
                                <input
                                        type="text"
                                        name="groom_family"
                                        placeholder="عيلة العريس"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.groom_family}
                                />
                                {/* {errors.groom_family && touched.groom_family && errors.groom_family} */}
                                <input
                                        type="text"
                                        name="bride_name"
                                        placeholder="اسم العروسة"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.bride_name}
                                />
                                {errors.bride_name && touched.bride_name && errors.bride_name}
                                <input
                                        type="text"
                                        name="bride_family"
                                        placeholder="عيلة العروسة"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.bride_family}
                                />
                                {/* {errors.bride_family && touched.bride_family && errors.bride_family} */}
                                <h2>اختر التصميم</h2>
                                <div className='select-image mb-4'>
                                    {wedding_cards.map((card)=>
                                    
                                    
                                        <div key={card.id} className="form-group d-inline-block m-3">
                                            <Field className="radio" type="radio" name="wedding_card_id" value={`${card.id}`} />
                                            <img src={card.image}/>
                                        </div>
                                        
                                    
                                    )}
                                </div>
                                {errors.wedding_card_id && touched.wedding_card_id && errors.wedding_card_id}
                                <button  className="submit" onClick={()=>nextpage()} type="submit" disabled={isSubmitting}>
                                    حفظ ومتابعة   
                                    {isSubmitting ? <div className='submit-loader'></div>:""}
                                </button>
                                </form>
                            )}
                        </Formik>
                        </div>
                        <div id="test-l-2 " className={display+" content mb-5"}>
                            <h1 className="header mt-5 mb-4">
                            معاينة بطاقة الدعوة          
                            </h1>
                            <div className='text-center'>
                                <img className='mb-4' src={image}/>
                            </div>
                            <h2>أضف قائمة الضيوف</h2>
                                <p>أضف بسهولة أسماء ضيوفك وعناوينهم، وسنتولى عملية إرسال الدعوات نيابةً عنك. قم بتحميل نموذج Excel الخاص بنا لرفع البيانات بطريقة سهلة وسلسة.</p>
                            <br/>
                            <Formik
                                initialValues={{ invitees_file:""}}
                                validate={values => {
                                    const errors = {};
                                    // if (!values.invitees_file) {
                                    //     errors.invitees_file = 'Required';
                                    // }
                                    return errors;
                                }}
                                onSubmit={(values, { setSubmitting }) => {

                                setTimeout(() => {
                                    console.log(values);
                                    console.log(allvalues)
                                    console.log({...values,...allvalues})
                                    values = {...values,...allvalues}
                                    axios.post(url+"/events", values,{
                                        headers: {
                                          'Accept': 'application/json',
                                          "ngrok-skip-browser-warning": "69420",
                                          "Authorization":`Bearer ${token}`,
                                          'Content-Type': 'multipart/form-data'
                                        }
                                      })
                                    .then(function (response) {
                                        window.location.replace("./event")
                                        Swal.fire({
                                            position: "center-center",
                                            icon: "success",
                                            title: response.data.message,
                                            showConfirmButton: false,
                                            timer: 1500
                                        });   
                                        
                                    })
                                    .catch(function (error) {
                                        Swal.fire({
                                            icon: "error",
                                            title: "خطأ",
                                            text: error.response.data.message,
                                        });
                                    }).finally(() => {
                                        setSubmitting(false);
                                    });
                                }, 0);
                                }}
                                >
                                {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                }) => (
                                <form onSubmit={handleSubmit} encType="multipart/form-data">
                                    <a className='downlood_exel' href={excel.excel_template_link} download>تحميل نموذج Excel</a>
                                    <input
                                        type="file"
                                        name="invitees_file"
                                        onChange={(event) => values.invitees_file=event.currentTarget.files[0] }
                                        onBlur={handleBlur}
                                        // value={values.invitees_file}
                                        accept=".xls, .xlsx"
                                    />
                                    {/* {errors.invitees_file && touched.invitees_file && errors.invitees_file} */}
                                    <button  className="submit"  type="submit" disabled={isSubmitting}>
                                    إرسال الدعوات
                                    {isSubmitting ? <div class='submit-loader'></div>:""}
                                    </button>
                                </form>
                                )}
                                </Formik>
                            <button type="submit" onClick={()=>prevpage()} className="btn btn-primary mt-5">رجوع</button>
                        </div>
                </div>
        </div>
    )
}

export default CreateEvent;